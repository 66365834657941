import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"

import NotFound from '../pages/notFound';
import Login from '../pages/auth/login'
import SignUp from '../pages/auth/signup'
import ConfirmEmail from '../pages/auth/confirmEmail'
import ConfirmEmailDone from '../pages/auth/confirmEmailDone'
import PasswordRecover from '../pages/auth/passwordRecover'
import PasswordChange from '../pages/auth/passwordChange'

import Home from '../pages/dash/home'
import RegisterCompany from '../pages/auth/registerCompany'
import UpdateLogo from '../pages/auth/updateLogo'
import Painel from '../pages/dash/painel'
import MessagesInbox from '../pages/messages/messagesInbox';
import MessagesSent from '../pages/messages/messagesSent';

import EmployeeList from '../pages/settings/employees/employeeList';
import EditUser from '../pages/settings/editUser'
import EditCompany from '../pages/settings/editCompany'
import GroupsList from '../pages/settings/groupsPerms/groupsList';
import EditGroup from '../pages/settings/groupsPerms/editGroup';
import AddEmployee from '../pages/settings/employees/addEmployee';
import EditEmployee from '../pages/settings/employees/editEmployee';
import EditEmployeePerms from '../pages/settings/employees/editEmployeePerms';
import EmployeeOrg from '../pages/dash/OrgEmployee/employeeOrg';

import InternalJobsListManagement from '../pages/settings/internalJobTitle/internalJobsList';
import InternalJobsManagement from '../pages/settings/internalJobTitle/internalJobsOrg'
import AddUpdateInternalJob from '../pages/settings/internalJobTitle/addUpdateInternalJob';
import BenefitsList from '../pages/settings/benefits/benefitsList';
import EditBenefit from '../pages/settings/benefits/editBenefit';
import CompetenciesList from '../pages/settings/competencies/competenciesList';
import EditCompetency from '../pages/settings/competencies/editCompeteny';
import SubCompaniesManagement from '../pages/settings/subCompanies/subCompaniesOrg';
import SubCompaniesListManagement from '../pages/settings/subCompanies/subCompaniesList';
import AddUpdateSubCompany from '../pages/settings/subCompanies/addUpdateSubCompany';
import DeptsListManagement from '../pages/settings/depts/deptList';
import DeptsManagement from '../pages/settings/depts/deptOrg';
import AddUpdateDept from '../pages/settings/depts/addUpdateDept';
import CostCenterManagement from '../pages/settings/costCenter/costCenterOrg';
import CostCenterListManagement from '../pages/settings/costCenter/costCenterList';
import AddUpdateCostCenter from '../pages/settings/costCenter/addUpdateCostCenter';
import PayStructureList from '../pages/settings/salary/payStructureList';
import CreatePayStructure from '../pages/settings/salary/createPayStructure';
import CreatePayGrade from '../pages/settings/salary/createPayGrade';
import CreatePayLevel from '../pages/settings/salary/createPayLevel';
import PayStructureDetail from '../pages/settings/salary/payStructureDetail';
import EditPayStructure from '../pages/settings/salary/editPayStructure';
import JobPositionList from '../pages/settings/jobPosition/jobPositionList';
import EditJobPosition from '../pages/settings/jobPosition/editJobPosition';
import AddJobPosition from '../pages/settings/jobPosition/addJobPosition';

import GoalPlanList from '../pages/goalPlan/goalPlanList';
import GoalPlanGoals from '../pages/goalPlan/goalPlanGoals';
import GoalPlanEdit from '../pages/goalPlan/goalPlanEdit';
import GoalPlanEditHistory from '../pages/goalPlan/goalPlanEditHistory';
import GoalPlanCascade from '../pages/goalPlan/goalPlanCascade';
import GoalEdit from '../pages/goalPlan/goalEdit';
import GoalEditStatus from '../pages/goalPlan/goalEditStatus';
import EmployeeGoals from '../pages/goalPlan/employeeGoals';
import EmployeeGoalsOrg from '../pages/goalPlan/employeeGoalsOrg';
import MyTeamGoals from '../pages/goalPlan/myTeamGoals';
import ArchivedGoals from '../pages/goalPlan/archivedGoals';
import CanceledGoals from '../pages/goalPlan/canceledGoals';

import CreatePerformanceReview from '../pages/performance/createPerformanceReview';
import CreatePerformanceReviewAll from '../pages/performance/createPerformanceReviewAll';
import PerformanceReviewProcess from '../pages/performance/performanceReviewProcess';
import ImportCompetencies from '../pages/performance/importCompetencies';
import ImportQuestions from '../pages/performance/importQuestions';
import ImportGoals from '../pages/performance/importGoals';
import MyTeamPerformance from '../pages/performance/myTeamPerformance';
import TakePerformance from '../pages/performance/takePerformance';
import TeamMatrix from '../pages/performance/teamMatrix';
import PerformanceList from '../pages/performance/performanceList';
import CreatePerformanceTemplate from '../pages/performance/createPerformanceTemplate';
import PerformanceTemplateProcess from '../pages/performance/performaceTemplateProcess';
import ImportGoalsToTemplate from '../pages/performance/importGoalsToTemplate';
import ImportCompetenciesToTemplate from '../pages/performance/importCompetenciesToTemplate';
import ImportQuestionsToTemplate from '../pages/performance/importQuestionsToTemplate';
import GeneralMatrix from '../pages/performance/generalMatrix';

import CompanyReports from '../pages/dash/reports/companyBI';

import CareerSummary from '../pages/career/summary';
import TeamSummary from '../pages/career/summaryTeam';


export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                
                <Route path="*" element={<NotFound />} />
                
                <Route exact path="/" element={ <Login /> } />
                {/* <Route path="/signup/" element={ <SignUp /> } /> */}
                <Route path="/recover/" element={ <PasswordRecover /> } />
                <Route path="/password/change/:uidb64/:token/" element={ <PasswordChange /> } />
                {/* <Route path="/confirm/email/:email/" element={ <ConfirmEmail /> } /> */}
                {/* <Route path="/confirm/email/:uidb64/:token/" element={ <ConfirmEmailDone /> } /> */}
                <Route path="/register/company/" element={ <RegisterCompany /> } />
                <Route path="/register/company/logo/" element={ <UpdateLogo /> } />
                <Route path="/home/" element={ <Home /> } />
                <Route path="/painel/" element={ <Painel /> } />
                <Route path="/messages/inbox/" element={ <MessagesInbox /> } />
                <Route path="/messages/sent/" element={ <MessagesSent /> } />

                <Route path="/settings/users-management/" element={ <EmployeeList /> } />
                <Route path="/settings/users-management/add/" element={ <AddEmployee /> } />
                <Route path="/settings/users-management/:id/edit/" element={ <EditEmployee /> } />
                <Route path="/settings/users-management/:id/edit/perms/" element={ <EditEmployeePerms /> } />

                <Route path="/settings/edit-user/" element={ <EditUser /> } />
                <Route path="/settings/edit-company/" element={ <EditCompany /> } />

                <Route path="/settings/perms-management/" element={ <GroupsList /> } />
                <Route path="/settings/perms-management/:id/edit/" element={ <EditGroup /> } />

                <Route path="/settings/benefits-management/" element={ <BenefitsList /> } />
                <Route path="/settings/benefit/edit/" element={ <EditBenefit /> } />

                <Route path="/settings/competencies-management/" element={ <CompetenciesList /> } />
                <Route path="/settings/competencies-management/edit/" element={ <EditCompetency /> } />

                <Route path="/settings/internal-job-management/" element={ <InternalJobsListManagement /> } />
                <Route path="/settings/internal-job-management/org/" element={ <InternalJobsManagement /> } />
                <Route path="/settings/internal-job-management/add-update/" element={ <AddUpdateInternalJob /> } />

                <Route path="/settings/subcompany-management/" element={ <SubCompaniesListManagement /> } />
                <Route path="/settings/subcompany-management/org/" element={ <SubCompaniesManagement /> } />
                <Route path="/settings/subcompany-management/add-update/" element={ <AddUpdateSubCompany /> } />

                <Route path="/settings/workstation-management/" element={ <DeptsListManagement /> } />
                <Route path="/settings/workstation-management/org/" element={ <DeptsManagement /> } />
                <Route path="/settings/workstation-management/add-update/" element={ <AddUpdateDept /> } />

                <Route path="/settings/costcenter-management/" element={ <CostCenterListManagement /> } />
                <Route path="/settings/costcenter-management/org/" element={ <CostCenterManagement /> } />
                <Route path="/settings/costcenter-management/add-update/" element={ <AddUpdateCostCenter /> } />

                <Route path="/settings/salary-structure/management/" element={ <PayStructureList /> } />
                <Route path="/settings/salary-structure/management/:id/detail/" element={ <PayStructureDetail /> } />
                <Route path="/settings/salary-structure/management/:id/edit/" element={ <EditPayStructure /> } />
                <Route path="/settings/salary-structure/management/create/" element={ <CreatePayStructure /> } />
                <Route path="/settings/salary-structure/management/:id/grade/create/" element={ <CreatePayGrade /> } />
                <Route path="/settings/salary-structure/management/:id/:grade_id/level/create/" element={ <CreatePayLevel /> } />

                <Route path="/settings/jobposition/management/" element={ <JobPositionList /> } />
                <Route path="/settings/jobposition/management/:id/edit/" element={ <EditJobPosition /> } />
                <Route path="/settings/jobposition/management/:id/add/" element={ <AddJobPosition /> } />

                <Route path="/goal/plan/" element={ <GoalPlanList /> } />
                <Route path="/goal/plan/:id/goals/" element={ <GoalPlanGoals /> } />
                <Route path="/goal/plan/:id/edit/" element={ <GoalPlanEdit /> } />
                <Route path="/goal/plan/:id/edit/history/" element={ <GoalPlanEditHistory /> } />
                <Route path="/goal/plan/:id/cascade/" element={ <GoalPlanCascade /> } />
                <Route path="/goal/:id/edit/" element={ <GoalEdit /> } />
                <Route path="/goal/:id/edit/status/" element={ <GoalEditStatus /> } />
                <Route path="/my/goals/" element={ <EmployeeGoals /> } />
                <Route path="/my/goals/organizational/" element={ <EmployeeGoalsOrg /> } />
                <Route path="/my/team/goals/" element={ <MyTeamGoals /> } />
                <Route path="/my/goals/archive/" element={ <ArchivedGoals /> } />
                <Route path="/my/goals/canceled/" element={ <CanceledGoals /> } />

                <Route path="/create/performance-review/" element={ <CreatePerformanceReview /> } />
                <Route path="/create/performance-review-all/" element={ <CreatePerformanceReviewAll /> } />
                <Route path="/performance-review/:id/process/" element={ <PerformanceReviewProcess /> } />
                <Route path="/performance-review/:id/import/competencies/" element={ <ImportCompetencies /> } />
                <Route path="/performance-review/:id/import/questions/" element={ <ImportQuestions /> } />
                <Route path="/performance-review/:id/import/goals/" element={ <ImportGoals /> } />
                <Route path="/my/team/performance/" element={ <MyTeamPerformance /> } />
                <Route path="/my/team/performance/matrix/" element={ <TeamMatrix /> } />
                <Route path="/performance/general/matrix/" element={ <GeneralMatrix /> } />
                <Route path="/performance-review/step/:id/take/" element={ <TakePerformance /> } />
                <Route path="/my/performance/list/" element={ <PerformanceList /> } />
                <Route path="/create/performance-template/" element={ <CreatePerformanceTemplate /> } />
                <Route path="/performance-template/:id/process/" element={ <PerformanceTemplateProcess /> } />
                <Route path="/performance-template/:id/import/goals/" element={ <ImportGoalsToTemplate /> } />
                <Route path="/performance-template/:id/import/competencies/" element={ <ImportCompetenciesToTemplate /> } />
                <Route path="/performance-template/:id/import/questions/" element={ <ImportQuestionsToTemplate /> } />

                <Route path="/my/org-chart/" element={ <EmployeeOrg /> } />

                <Route path="/company/reports/" element={ <CompanyReports /> } />

                <Route path="/my/career/summary/" element={ <CareerSummary /> } />
                <Route path="/career/summary/:id/" element={ <TeamSummary /> } />
           
            </Routes>
        </BrowserRouter>
    )
}