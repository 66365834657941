import http from "../http"

class CareerService {

    async getEmployeeSummary(data) {
        const rqst = await http.post('/employee/summary/', data)
        return rqst.data
    }

}

export default new CareerService()